#terminal {
   height: 200px;
   width: 600px;
}

:root {
    --color: #00bf00;
    --background: #062006;
    --link-color: #3377FF;
    --glow: 0.0;
    --font: monospace;
}

.tv {
}
