.coinArea {
    display: flex;
}

.currentCoins {
    width: 400px;
    text-align: center;
}

.rebootCoins {
    width: 400px;
    justify-content: center;
    align-items: center;
}

.progressBack {
    background-color: #606060;
}

.progressFill {
    background-color: #00c000;
}