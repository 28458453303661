body {
	margin: 0px;
	box-sizing: border-box;
	background-color: rgb(80, 80, 80);
}

.mainLayoutGrid {
	display: grid;
	grid-template-columns: 400px auto;
	grid-template-rows: 100px auto;
	padding: 0px;
	grid-gap: 4px;
}

.level1Area {
	background-color: #828382;
}

.terminalFooter {
	display: flex;
	position: fixed;
	bottom: 0;
}

.moneyDiv {
	display: flex;
}

.cpuAreaDiv {
	display: flex;
	flex-direction: column;
}

.cpuUpgradesDiv {
	display: flex;
	flex-direction: column;
	position: absolute;
	margin-left: 500px;
}

.nftAreaDiv {
	background-color: rgb(80, 80, 80);
	/* doesn't work, it should fill the remaining vertical screen space and use scrolling for overflow. */
	display: flex;
	flex-flow: column;
	height: 100%;
	flex: 1 1 auto;
}

.verticalScrollArea {
	overflow-y: scroll;
	overflow-x: hidden;
}