.cpuLayout {
	display: flex;
	justify-content: center;
	align-items: center;
}

.cpuLabel {
	width: auto;
	text-align: center;
	flex: 1;
	text-shadow: 0 0 10px #fff;
	font-weight: 500;
}

#manualWorkButton {
	border-radius: 10px;
	width: 100px;
	height: 100px;
	display: block;
	margin-top: 25px;
	margin-bottom: 25px;
	margin-left:auto;
	margin-right:auto;
	background-color: #444;
	border: 1px solid black;
	padding: 5px;
	color: rgba(1, 1, 1, 1);
	box-shadow: 1px 1px 20px 0px;
}
