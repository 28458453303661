:root {
    --col-common-text:rgb(183, 183, 183);
    --col-uncommon-text:rgb(30, 225, 50);
    --col-rare-text:rgb(151, 166, 255);
    --col-epic-text:rgb(219, 161, 255);
    --col-legendary-text:rgb(255, 187, 0);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: content-box;
  user-select: none;
}

hr {
  background-color:white;
  height: 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes textFloatAnim {
    0%   { transform: translate(-50%, -20px); opacity: 1.0; }
    50% { transform: translate(-50%, -100px); opacity: 0.0; }
}

#moneyText {
  padding-top: 5px;
  font-size: 40px;
  text-align: center;
  text-shadow: 0 0 10px #fff
}

#incomeText {
  font-size: larger;
  text-align: center;
  text-shadow: 0 0 10px #fff
}

#buttCoinText {
  padding-top: 5px;
  font-size: 40px;
  text-shadow: 0 0 10px #fff
}

#buttCoinTextLabel {
  font-size: larger;
  text-shadow: 0 0 10px #fff
}

.money-float {
	color:white;
	text-shadow: white 0px 0px 5px;
	font-weight: 200;
	font-size: medium;
	pointer-events: none;
	position: absolute;
	animation: textFloatAnim 4s;
	animation-direction: normal;
	animation-timing-function: ease-out;
	text-align: center;
	transform: translate(-50%, -50%);
	text-shadow: white 0px 0px 5px;
}
