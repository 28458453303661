.cpuUpgradeBtn {
    display: block;
    width: 600px;
    height: 100px;
    padding: 6px 0;
    border: 10px solid #ddd;
    background-color: whitesmoke;
    margin: 15px;
    font-size: 24px;
    text-shadow: 1px 1px 1px rgb(180, 180, 180);
    border-radius: 10px;
    text-align: left;
}

.boxGreen {
    box-shadow: 0px 0px 10px rgb(0, 255, 0);
}

.boxRed {
    box-shadow: 0px 0px 10px red;
}

.upgradeTextArea {
    margin-left: 20px;
    margin-right: 20px;
}

.upgradeName {
    font-size: 20px;
    font-weight: bold;
}

.textInner {
    position: absolute;
    display: flex;
    width: 100%;
}

.upgradePrice {
    font-size: 20px;
    text-align: left;
    flex: 0 0 50%;
    margin-left: 10px;
    font-weight: bold;
}

.displayTime {
    padding-top: 4px;
    font-size: 15px;
    text-align: right;
    width: 50px;
    flex: 0 0 50%;
    margin-left: -15px;
}

.cpuUpgradeBtnPctOuter {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 15px;
    position: relative;
    height: 30px;
    background-color: rgb(255, 154, 154);
}

.cpuUpgradeBtnPctInner {
    height: 30px;
    background-color: rgb(136, 255, 172);
}

.canAfford {
    color:rgb(0, 0, 0);
}
.cannotAfford {
    color:rgb(0, 0, 0);
}
